import "./Servicios.css";
import "../Card-Hover/Card-Hover.css";
import { CardHover } from "../Card-Hover/Card-Hover";
import { useEffect, useState } from "react";
const servicios = require("./servicios.json");

export const Servicios = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  return (
    <div className="servicios">
      <img
        src="../../assets/paisajes/cordoba 2022 - 1.jpg"
        alt="About"
        className="img-bg"
      />
      <h1 className="servicios-h1">Nuestros equipos y servicios</h1>

      <div className="servicios__cardContainer">
        {servicios.map((s) => {
          if (s.id < 5) {
            return (
              <CardHover
                key={s.id}
                img={s.img}
                name={s.name}
                description={s.description}
              />
            );
          }
        })}
           
              <div className="servicios_otros">
                <h2 className="servicios_otros-h2">Otros servicios</h2>
                <ul className="servicios_otros-ul">
                  {servicios.map((s) => {
                    if (s.id > 4) {
                      return <li className="servicios_otros-li" key={s.id}>
                        <h3>{s.name}:</h3>
                        <p>
                          {s.description}
                        </p>
                        </li>;
                    }
                  })}
                </ul>
                
                <p className="servicios_otros-p">
                  Si no encontraste lo que buscabas, no dudes en contactarnos.
                </p>
                <a
                  href="#/contact"
                  className="servicios_otros-a"
                >
                  <button className="servicios_otros-button">Contacto</button>
                </a>
              </div>
          
    
    </div>
    </div>
  );
};
