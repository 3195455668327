import { Card } from "../Card/Card";
import './Consideraciones.css'
const consideraciones = require('./consideraciones.json');


export const Consideraciones = () => {
    return (
        <div className="consideraciones-container">
            <div className="consideraciones-container-h2">
            <h2>Consideraciones Previas</h2>
            </div>
            <div className="consideraciones-container-card">
                {consideraciones.map((c)=>{
                      return  <Card
                        key={c.id}
                        img = {c.img}
                        titulo = {c.titulo}
                        descripcion = {c.descripcion}
                        />
                    
                })}
            
                
            </div>
        </div>
    );
}