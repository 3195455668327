import { Nav, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./HeaderCover.css";
import { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import NavBarMobile  from "../NavBarMobile/NavBarMobile";



export const HeaderCover = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  return (
    <div className="headerCover">
      <div className="logo-left">
        {window.location.pathname === "/#" ? 
        (
          <img src="#" alt="" srcset="" />
        ) : (
          <Link to="/#">
            <img 
            src="../../assets/logos/color-1-logo1.png" 
            alt="logo"
            className={windowWidth > 1000 ? "logo" : "none"}
            />
            <img 
            src="../../assets/logos/color-1-logo3.png" 
            alt="logo"
            className={windowWidth <= 1000 ? "logo-mobile" : "none"}
            />
          </Link>
        )}
      </div>

      {
        windowWidth <= 1000 ? (
          <div className="ul-right">
            <NavBarMobile />
          </div>
        ) : (
          <nav className="ul-right">
        
        <ul>
          <li>
            <Link to="/about">Sobre Nosotros</Link>
          </li>
          <li>
            <Link to="/products">Equipos y Servicios</Link>
          </li>
          <li>
            <Link to="/contact">Contacto</Link>
          </li>
        </ul>
      </nav>
    )
  }
    </div>

  );};
