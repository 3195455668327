import './Cover.css';

export const Cover = () => {
    const video = '../../assets/videos/cover.mp4'
    const logo = '../../assets/logos/color-1-logo2.png'
    return (
        <>
        <video autoPlay muted loop src={video} id="myVideo" />
        <div className="cover-div">
        <img src={logo} alt="Logo" />
    
       
        </div>
        </>
    );
    }