import React, { useState, useEffect } from "react";
import "./Card-Hover.css";

export const CardHover = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimensions);
    return () => {
      window.removeEventListener("resize", setWindowDimensions);
    };
  }, []);

  const cardHover = document.getElementsByClassName('cardHover');
  const cardHoverContent = document.getElementsByClassName('cardHover-content');

  const toggleFunction = () => {
    cardHoverContent.classList.toggle('cardHover-content-clicked');
    cardHover.classList.toggle('cardHover-clicked');
    console.log("clicked");
  };

    return (
        <div className="cardHover" onTouchEnd={toggleFunction}>
             <img  src={props.img} alt={props.name} className="cardHover__img"/>
    <div class="cardHover-content">
      <h2   className="cardHover_h2" >
        {props.name}
      </h2>
      <p  className="cardHover_p" >
        {props.description}
      </p>
    </div>
    </div>
    );
};
