
import { FooterCover } from "../components/Footer/FooterCover";
import { Contacto } from "../components/Contacto/ContactoEmail";
import './ContactPage.css'
import { HeaderCover } from "../components/Header/HeaderCover";

export const ContactPage = () => {
    return (
        <div className="homeCover-div">
            <HeaderCover />
            <Contacto/>
          
            <FooterCover />
        </div>
    );
}