import "./Contacto.css";
import { useRef } from "react";
import { Loader } from "../Loader/Loader";
import { Message } from "../Message/Message";
import { useForm } from "../../hooks/useForm";
import { FaWhatsapp } from "react-icons/fa";

export const Contacto = () => {
  const initialForm = {
    user_name: "",
    user_phone: "",
    user_email: "",
    message: "",
  };
  const validationsForm = (form) => {
    let errors = {};
    let regexName = /^[a-zA-ZÀ-ÿ\s]{1,100}$/; // Letras y espacios, pueden llevar acentos.
    let regexPhone = /^\d{7,14}$/; // 7 a 14 numeros.
    if (!regexName.test(form.user_name)) {
      errors.user_name = "* El nombre solo puede contener letras y espacios.";
    } else if (form.user_name.length > 100) {
      errors.user_name = "*El nombre no puede superar los 100 caracteres.";
    } else if (!form.user_name) {
      errors.user_name = "* El nombre es obligatorio.";
    }
    if (!form.user_phone) {
      errors.user_phone = "* El telefono es obligatorio.";
    } else if (!regexPhone.test(form.user_phone)) {
      errors.user_phone = "* El telefono solo puede contener numeros.";
    }
    if (!form.user_email) {
      errors.user_email = "* El email es obligatorio.";
    } else if (!/\S+@\S+\.\S+/.test(form.user_email)) {
      errors.user_email = "* El email no es valido.";
    }
    if (!form.message) {
      errors.message = "* El mensaje es obligatorio.";
    } else if (form.message.length > 500) {
      errors.message = "* El mensaje no puede superar los 500 caracteres.";
    }
    return errors;
  };

  const {
    form1,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);

  const form = useRef();
  const mailto = "controlesderadar@gmail.com";

  return (
    <div className="contacto">
      <img
        src="../../assets/paisajes/cordoba 2022 - 1.jpg"
        alt="About"
        className="img-bg"
      />
      <h1 className="contacto-h1">Contáctanos</h1>
      <p className="contacto-p">
        Puede enviarnos un email llenando el siguiente formulario o escribirnos
        a nuestro correo electrónico: <br />
        <a href={`mailto:${mailto}`}>{`${mailto}`}</a>
      </p>
      <div className="contacto">
        <form ref={form} onSubmit={handleSubmit} className="contacto__form">
          <input
            type="text"
            id="name"
            name="user_name"
            placeholder="Nombre"
            className="contacto__form__input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={form1.user_name}
            required
          />
          {errors.user_name && (
            <p className="contacto__form__error">{errors.user_name}</p>
          )}
          <input
            type="tel"
            name="user_phone"
            id="phone"
            placeholder="Numero de contacto"
            className="contacto__form__input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={form1.user_phone}
            required
          />

          {errors.user_phone && (
            <p className="contacto__form__error">{errors.user_phone}</p>
          )}

          <input
            type="email"
            id="email"
            name="user_email"
            placeholder="Email"
            className="contacto__form__input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={form1.user_email}
            required
          />

          {errors.user_email && (
            <p className="contacto__form__error">{errors.user_email}</p>
          )}

          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            placeholder="Mensaje"
            className="contacto__form__textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            value={form1.message}
            required
          ></textarea>
          {errors.message && (
            <p className="contacto__form__error">{errors.message}</p>
          )}
          <button type="submit" className="contacto__form__button">
            Enviar
          </button>
        </form>

        {loading && <Loader />}
        {response && (
          <Message msg="Los datos han sido enviados" bgColor="#263238" />
        )}
        <p className="contacto-p">
          También puede comunicarse con nosotros a través de nuestros números de
          teléfono:
        </p>
        <div className="contacto__form__div__button-number">
        <button className="contacto__form__button contacto__form__button-number">
          <a
            className="contacto-p-a"
            href="https://api.whatsapp.com/send?phone=5491138066063&text=Buenos%20d%C3%ADas,%20quisiera%20mas%20informaci%C3%B3n%20de%20sus%20servicios%20de%20seguridad%20vial."
          >
           <FaWhatsapp /> +54 9 11-38066063
          </a>
        </button>
        <button className="contacto__form__button contacto__form__button-number">
          <a
            className="contacto-p-a"
            href="https://api.whatsapp.com/send?phone=5493513754869&text=Buenos%20d%C3%ADas,%20quisiera%20mas%20informaci%C3%B3n%20de%20sus%20servicios%20de%20seguridad%20vial."
          >
           <FaWhatsapp /> +54 9 351375-4869
          </a>
        </button>
        <button className="contacto__form__button contacto__form__button-number">
          <a
            className="contacto-p-a"
            href="https://api.whatsapp.com/send?phone=5493541660927&text=Buenos%20d%C3%ADas,%20quisiera%20mas%20informaci%C3%B3n%20de%20sus%20servicios%20de%20seguridad%20vial."
          >
           <FaWhatsapp /> +54 9 3541-660927
          </a>
        </button>
        </div>
      </div>
    </div>
  );
};
