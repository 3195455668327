import { useState } from "react";
import { helpHttp } from "../helpers/helpHttp";

export const useForm = ( initialForm, validateForm) => {
  const [errors, setErrors] = useState({});
  const [form1, setForm1] = useState(initialForm);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm1({
      ...form1,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validateForm(form1));

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateForm(form1));

    if(Object.keys(errors).length === 0) {
      console.log("Formulario enviado");
      setLoading(true);
      helpHttp().post("https://formsubmit.co/ajax/controlesderadar@gmail.com",
      {
        body: form1,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
      ).then((res) => {
         setLoading(false);
          setResponse(true);
          setForm1(initialForm);
          setTimeout(() => setResponse(false), 5000);
      });
    } else {
      console.log("Formulario con errores");
      setResponse(false);
      setLoading(false);
    }

  };

    return {
        form1,
        errors,
        loading,
        response,
        handleChange,
        handleBlur,
        handleSubmit

};
};
