export const Message = ({ msg, bgColor }) => {
        let styles = {
          padding: "1rem",
          marginBottom: "1rem",
          textAlign: "center",
          color: "#FFFBE2",
          fontWeight: "bold",
          backgroundColor: bgColor,
          fontFamily: "Roboto, sans-serif"
        };
      
        return (
          <div style={styles}>
            <p dangerouslySetInnerHTML={{ __html: msg }} />
          </div>
        );
      };