import { Consideraciones } from "../Consideraciones/Consideraciones.jsx";
import { Valores } from "../Valores/Valores.jsx";
import "./About.css";

export const About = () => {
  return (
    <>
      <img
        src="../../assets/paisajes/cordoba 2022 - 1.jpg"
        alt="About"
        className="img-bg"
      />
      <div className="About-div">
        <div className="About-div-sobreNosotros">
          <h1 className="About-h1">Sobre nosotros</h1>
          <div className="About-div-p">
            <p className="About-p">
              Somos una empresa que crea soluciones innovadoras para fomentar la
              prevención vial y reducir los siniestros viales en calles,
              avenidas y rutas, generando seguridad de circulación e
              incentivando a mantener la precaución a la hora de conducir.
              Nuestra trayectoria se basa en la experiencia de nuestros
              fundadores, quienes desde hace más de 10 años se dedican a la
              prevención y la seguridad vial.
              <br />
              {/* Actualmente, estamos trabajando en la implementación de nuevos
              modelos de negocios, orientandonos en área de software, que nos
              permitan llegar a más personas y generar un impacto positivo en la
              sociedad. */}
            </p>
          </div>
        </div>
        <div className="About-div-nuestraMision about-p-marco">
          <h2 className="About-h2">Nuestra Misión</h2>
          <div className="About-div-p ">
            <p className="About-p ">
              Garantizar la seguridad de los conductores y peatones, a través de
              la implementación de soluciones innovadoras que fomenten la
              prevención vial y reduzcan los siniestros viales en calles,
              avenidas y rutas.
            </p>
          </div>
        </div>
        <div className="About-div-valores">
          <Valores />
        </div>
        <div className="About-div-nuestraVision about-p-marco">
          <Consideraciones />
        </div>
      </div>
    </>
  );
};
