import './Valores.css';
const valores = require('./valores.json');

export const Valores = () => {
    return (
        <>
        <img
            src="../../assets/paisajes/cordoba 2022 - 1.jpg"
            alt="Valores"
            className="img-bg"
        />
        <div className="Valores-div">
            <div className="Valores-div-valores">
            <h2 className="Valores-h2">Nuestros Valores</h2>
            </div>
            <div className="Valores-div-cardValores-container">
            {valores.map((valor) => (
                <div className="Valores-div-card" key={valor.id}>
                    <img
                    src={valor.img}
                    alt={valor.alt}
                    className="Valores-img"
                    />
                    <p className="Valores-p">{valor.titulo}</p>
                </div>              
            ))


            }
                
           
            </div>
        </div>
        </>
    );
    }