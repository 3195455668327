
import { FooterCover } from "../components/Footer/FooterCover";
import { Cover } from "../components/Cover/Cover";
import './HomePageCover.css'
import { HeaderCover } from "../components/Header/HeaderCover";

export const HomePageCover = () => {
    return (
        <div className="homeCover-div">
            <HeaderCover />
            <Cover/>
          
            <FooterCover />
        </div>
    );
}