import { HeaderCover } from '../components/Header/HeaderCover';
import { FooterCover } from '../components/Footer/FooterCover';
import { About } from '../components/About/About';
import './AboutPage.css';

export const AboutPage = () => {
    return (
        <div className='AboutPage'>
        <HeaderCover />
        <About />
        <FooterCover />
        </div>
    );
    }