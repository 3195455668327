import { Servicios } from "../components/Servicios/Servicios.jsx"
import { HeaderCover } from "../components/Header/HeaderCover"
import { FooterCover } from "../components/Footer/FooterCover"

export const ServicesPage = () => {
    return (
        <div>
             <HeaderCover />
                <Servicios/>
              <FooterCover />
        </div>
    )
}