import "./Card.css";
export const Card = (props) => {
  return (
    <div className="card">
      <div className="card-image">
        <img src={props.img} alt={props.titulo} />
      </div>
      <div className="card-p">
        <p>{props.descripcion}</p>
      </div>
    </div>
  );
};
