import { Routes, Route, HashRouter } from 'react-router-dom';
import { ContactPage } from '../pages/ContactPage';
import { HomePageCover } from '../pages/HomePageCover';
import { AboutPage } from '../pages/AboutPage';
import { ServicesPage } from '../pages/ServicesPage';


export const AppRouter = () => {
    return (
        <HashRouter>
            <Routes>
                {/* <Route path="/" element={<HomePage/>}/> */}
                <Route path="/" element={<HomePageCover/>}/>
                <Route path="/about" element={<AboutPage/>}/>
                <Route path="/contact" element={<ContactPage/>}/>
                <Route path="/products" element={<ServicesPage/>}/>
                <Route path="*" element={<h1>Error 404: Not found</h1>}/>

                {/* <Route path="*" element={<h1>Error 404: Not found</h1>}/> */}
            </Routes>
            </HashRouter>
    );
}