import './Loader.css'
export const Loader = () => {
    return (
        <div className="lds-ring">
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>
                <div className="loader__circle"></div>

       
    </div>
    );
    }